import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"


export default class MarkaPage extends React.Component {
  render() {
    const data = this.props.data
        const pagebgmarka = data.pagebgmarka.childImageSharp.fluid


return (
  <Layout pageTitle="Marka Tescili">
  <SEO title="Marka - Patent Tescili" keywords={[`Marka Tescili`, `Web Tasarım`, `Marka Patent İşlemleri`]} description="Marka Tescili - patent işlemleri - yazılım tescili ve çok daha fazlası için" />

    <section className="bginpage">
    <Img fluid={pagebgmarka}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Web Tasarım"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
        Marka Tescili
             </h1>
             <p> Kurumsal Markanızı , patent işlemlerinizi ,yazılım tescilinizi ve çok fazlasını İstanbulwebtasarım olarak sizlere tüm süreçte şeffaflık ile sunuyoruz.</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>
  <section className="maincontent">
  <Container>

    <Row>
      <Col md="12" className="acik">
      <h1 className="font-size-normal  section-title">
               <small>Web Tasarım &amp; E-Ticaret &amp; Arama Motoru Optimizasyonu &amp; Kurumsal e-Posta</small>
               Marka Tescili
                <div className="bar"></div>
           </h1>
  <p>
    Marka, bir şahıs ya da firmanın ürettiği mal veya hizmetlerin, diğer şahıslar ya da firmalar tarafından üretilen mal veya hizmetlerden ayırt edilmesini sağlayan, o şahıs yada firmayı temsil eden, baskı yoluyla yayınlanabilen ve çoğaltılabilen işaretlere verilen addır. Sizi yada firmanızı temsil eden markanızın sizcede yasal olarak korunması ve bu yasallığı kanıtlayacak bir belgesinin olması gerekmez mi? İşte bu yasallığın kanıtı marka tescilinizdir.Control Bilişim ile gerçekleştireceğiniz marka başvuru işlemleri Ankara avukatlarımız aracılığıyla gerçekleşecektir. Daha sonra TPE tarafından onaylanan marka tescilizle birlikte birçok avantaj elde etmiş olacaksınız.Haksız kazançlara itiraz, markanızın marka onayı olmayan firmalarının kullanması halinde itiraz hakkı gibi taleplerinizi bu resmi belge ile rahatça gerçekleştirebileceksiniz. Marka başvurularınız anında işleme alınarak talep ettiğiniz markanın boş ve uygun olması halinde size 3 iş günü içerisinde marka başvuru belgeniz gönderilecektir.</p>
    </Col>
    </Row>
    </Container>
    </section>
  </Layout>
)
}
}
export const pageQuery = graphql`
query {
pagebgmarka: file(
 relativePath: { regex: "/bg10.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
